import { InternData } from '../../types/internData';
import { requestHeaders } from './requestHeaders';

export const getIntern = async (id: string): Promise<InternData | null> => {
    try {
        const response = await fetch((process.env.REACT_APP_API_URL as string) + '/users?id=' + id, {
            method: 'GET',
            headers: requestHeaders
        });
        const data = (await response.json()).data[0];

        return {
            id: data.id,
            firstname: data.firstname,
            lastname: data.lastname
        };
    } catch (error) {
        console.error(error);
        return null;
    }
};
