import React, { useState, useContext } from 'react';
import '../styles/styles-components/drawerMenu.css';
import i18n from '../i18n/i18n';
import { ThemeContext } from '../contexts/themeContext';
import { useTheme } from '../hooks/useTheme';

//MUI imports
import { Drawer, Box } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';

// MUI icons imports
import SegmentIcon from '@mui/icons-material/Segment';
import CloseIcon from '@mui/icons-material/Close';
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import HomeWorkOutlinedIcon from '@mui/icons-material/HomeWorkOutlined';
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import CheckIcon from '@mui/icons-material/Check';
import ChecklistOutlinedIcon from '@mui/icons-material/ChecklistOutlined';

// Component imports
import DrawerLink from './DrawerLink';

const DrawerMenu: React.FC = () => {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [expanded, setExpanded] = useState<string | false>(false);
    const { toggleTheme, currentTheme } = useContext(ThemeContext);
    const theme = useTheme();

    const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
        setExpanded(newExpanded ? panel : false);
    };

    const handleThemeChange = (event: React.MouseEvent<HTMLElement>, newTheme: string) => {
        if (newTheme !== null) {
            toggleTheme();
        }
    };

    return (
        <>
            <SegmentIcon id="openMenu" onClick={() => setIsDrawerOpen(true)} />
            <Drawer anchor="right" style={{ display: 'flex', justifyContent: 'center' }} open={isDrawerOpen} onClose={() => setIsDrawerOpen(false)}>
                <Box id="drawerBox" sx={{ backgroundColor: theme.palette.background.default, color: theme.palette.text.primary }}>
                    <Box id="closeMenu">
                        <CloseIcon style={{ marginLeft: '0.75em', color: theme.palette.text.primary }} onClick={() => setIsDrawerOpen(false)} />
                    </Box>
                    <Box id="drawerContentOne">
                        <DrawerLink to="/sessions" icon={ListAltOutlinedIcon} text={i18n.t('Menu.sessions')} />
                        <DrawerLink to="/account" icon={AccountCircleOutlinedIcon} text={i18n.t('Menu.account')} />
                        <DrawerLink to="/center" icon={HomeWorkOutlinedIcon} text={i18n.t('Menu.center')} />
                        <DrawerLink to="/attendance-sheet" icon={ChecklistOutlinedIcon} text={i18n.t('Menu.attendanceSheet')} />
                        <Accordion style={{ boxShadow: 'none', border: 'none', color: theme.palette.text.primary }} expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                            <AccordionSummary sx={{ display: 'flex', alignItems: 'center', color: theme.palette.text.primary, backgroundColor: theme.palette.background.default }}>
                                <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
                                    <DarkModeOutlinedIcon
                                        style={{
                                            marginLeft: '0.10em',
                                            marginRight: '0.7em',
                                            transform: expanded === 'panel1' ? 'rotate(180deg)' : 'rotate(0deg)',
                                            transition: 'transform 0.3s ease'
                                        }}
                                    />
                                    {i18n.t('Menu.appearance.title')}
                                </Box>
                            </AccordionSummary>
                            <AccordionDetails style={{ backgroundColor: theme.palette.background.default }}>
                                <ToggleButtonGroup value={currentTheme} exclusive onChange={handleThemeChange} style={{ width: '90%', marginLeft: '0.75em', display: 'flex', flexDirection: 'column' }}>
                                    <Box
                                        sx={{
                                            width: '100%',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                            marginTop: '0.1em',
                                            marginBottom: '0.4em',
                                            fontSize: '0.9em'
                                        }}
                                    >
                                        {i18n.t('Menu.appearance.light')}
                                        <ToggleButton
                                            value="light"
                                            selected={currentTheme === 'light'}
                                            style={{
                                                width: '1.3em',
                                                height: '1.3em',
                                                backgroundColor: currentTheme === 'light' ? '#5bb3d9' : theme.palette.background.default,
                                                border: '2px solid #5bb3d9',
                                                borderRadius: '50%',
                                                padding: 0,
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center'
                                            }}
                                        >
                                            <CheckIcon style={{ color: currentTheme === 'light' ? 'white' : theme.palette.background.default, fontSize: '1em' }} />
                                        </ToggleButton>
                                    </Box>
                                    <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '0.4em', fontSize: '0.9em' }}>
                                        {i18n.t('Menu.appearance.dark')}
                                        <ToggleButton
                                            value="dark"
                                            selected={currentTheme === 'dark'}
                                            style={{
                                                width: '1.3em',
                                                height: '1.3em',
                                                backgroundColor: currentTheme === 'dark' ? '#5bb3d9' : 'white',
                                                border: '2px solid #5bb3d9',
                                                borderRadius: '50%',
                                                padding: 0,
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center'
                                            }}
                                        >
                                            <CheckIcon style={{ color: 'white', fontSize: '1em' }} />
                                        </ToggleButton>
                                    </Box>
                                </ToggleButtonGroup>
                            </AccordionDetails>
                        </Accordion>
                        <DrawerLink to="/help" icon={HelpOutlineOutlinedIcon} text={i18n.t('Menu.help')} />
                        <DrawerLink to="/about" icon={InfoOutlinedIcon} text={i18n.t('Menu.about')} />
                    </Box>
                    <Box id="drawerContentTwo">
                        <LogoutOutlinedIcon style={{ margin: '0.5em 0.75em', color: '#FF6642' }} />
                        <p id="logout">{i18n.t('Menu.logout')}</p>
                    </Box>
                </Box>
            </Drawer>
        </>
    );
};

export default DrawerMenu;
