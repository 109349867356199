import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import i18n from '../i18n/i18n';

// Styles
import '../styles/session.css';

// Components
import HeaderBar from '../components/HeaderBar';
import SignEvalButton from '../components/SignEvalButton';
import DeleteSession from '../components/DeleteSession';

// MUI imports
import PersonOffOutlinedIcon from '@mui/icons-material/PersonOffOutlined';
import GestureOutlinedIcon from '@mui/icons-material/GestureOutlined';
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import HttpsOutlinedIcon from '@mui/icons-material/HttpsOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import QrCode2Icon from '@mui/icons-material/QrCode2';

// Helpers
import { useTheme } from '../hooks/useTheme';
import { getSession } from '../helpers/api/callSession';

// Types
import { SessionData } from '../types/sessionData';

const Session: React.FC = () => {
    const [loading, setLoading] = useState<boolean>(true);
    const { id } = useParams<{ id: string }>();
    const theme = useTheme();

    const [session, setSession] = useState<SessionData>({
        id: '',
        trainingLabel: '',
        sessionToken: '',
        sessionName: '',
        sessionBegin: '',
        sessionEnd: '',
        trainerName: '',
        trainees: Array<string>(),
        slotsId: Array<string>()
    });

    useEffect(() => {
        const fetchSession = async () => {
            if (id) {
                const sessionData = await getSession(id);
                if (sessionData) {
                    setSession(sessionData);
                }
                setLoading(false);
            } else {
                console.error('id is null or undefined');
                setLoading(false);
            }
        };

        fetchSession();
    }, [id]);

    return (
        <div id="oneSession" style={{ backgroundColor: theme.palette.background.default, color: theme.palette.text.primary }}>
            <HeaderBar />
            {loading ? (
                <div>
                    <CircularProgress sx={{ color: theme.palette.text.custom }} />
                </div>
            ) : (
                <>
                    <div id="sessionInfo">
                        <div id="title-codes">
                            <h1 style={{ color: theme.palette.secondary.main }}>{session.trainingLabel}</h1>
                            <div id="codes">
                                <QrCode2Icon id="qrCode" style={{ color: theme.palette.text.primary }} />
                                <p style={{ color: theme.palette.text.custom }}>{session.sessionToken}</p>
                            </div>
                        </div>
                        <div id="infos">
                            <div className="info" style={{ backgroundColor: theme.palette.background.paper, color: theme.palette.text.primary }}>
                                <p style={{ color: theme.palette.text.primary }}>{i18n.t('Session.info.session')} : </p>
                                {session.sessionName}
                            </div>
                            <div className="info" style={{ backgroundColor: theme.palette.background.paper, color: theme.palette.text.primary }}>
                                <p style={{ color: theme.palette.text.primary }}>{i18n.t('Session.info.date')} : </p>
                                {`${session.sessionBegin} - ${session.sessionEnd}`}
                            </div>
                            <div className="info" style={{ backgroundColor: theme.palette.background.paper, color: theme.palette.text.primary }}>
                                <p style={{ color: theme.palette.text.primary }}>{i18n.t('Session.info.trainer')} : </p>
                                {session.trainerName}
                            </div>
                        </div>
                        <div id="stats">
                            <div id="stat1">
                                <PersonOffOutlinedIcon />
                                <p>0</p>
                            </div>
                            <div id="stat2">
                                <GestureOutlinedIcon />
                                <p>{`0 / ${session.trainees.length}`}</p>
                            </div>
                            <div id="stat3">
                                <InventoryOutlinedIcon />
                                <p>{`0 / ${session.trainees.length}`}</p>
                            </div>
                        </div>
                    </div>
                    <div id="sessionActions">
                        <div id="intern-sign-evaluation">
                            <SignEvalButton route={`/sessions/${session.id}/interns`} variant="contained" size="large" backgroundColor="blue" startIcon={<PeopleAltOutlinedIcon />}>
                                <p style={{ color: theme.palette.text.secondary }}>{i18n.t('Session.actions.trainees')}</p>
                                {session.trainees.length}
                            </SignEvalButton>
                            <SignEvalButton variant="contained" size="large" backgroundColor="blue" startIcon={<GestureOutlinedIcon />}>
                                <p style={{ color: theme.palette.text.secondary }}>{i18n.t('Session.actions.signature')}</p>
                                &nbsp;
                            </SignEvalButton>
                            <SignEvalButton variant="contained" size="large" backgroundColor="blue" startIcon={<InventoryOutlinedIcon />}>
                                <p style={{ color: theme.palette.text.secondary }}>{i18n.t('Session.actions.evaluation')}</p>
                                &nbsp;
                            </SignEvalButton>
                        </div>
                        <div id="delete-close">
                            <DeleteSession sessionId={id} sessionName={session.trainingLabel} />
                            <SignEvalButton id="closeButton" variant="contained" backgroundColor="purple" size="medium" endIcon={<HttpsOutlinedIcon />}>
                                <p style={{ color: theme.palette.text.secondary }}>{i18n.t('Session.actions.close')}</p>
                            </SignEvalButton>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default Session;
