import React from 'react';
import { useTheme } from '../hooks/useTheme';
import '../styles/styles-components/headerBar.css';
import { Link, useNavigate, useLocation } from 'react-router-dom';

// Components
import DrawerMenu from './DrawerMenu';

// Images
import logoSignEvalDefault from '../assets/images/logoSignEval.png';
import logoSignEvalWhite from '../assets/images/logoSignEvalWhite.png';

// MUI imports
import AppBar from '@mui/material/AppBar';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

const HeaderBar: React.FC = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const location = useLocation();

    const handleBackClick = () => {
        navigate(-1);
    };

    const logoSignEval = theme.palette.mode === 'light' ? logoSignEvalDefault : logoSignEvalWhite;

    const shouldShowBackButton = location.pathname !== '/sessions';

    return (
        <div className="header" style={{ backgroundColor: theme.palette.background.default }}>
            <AppBar
                id="appbar"
                position="static"
                style={{
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    width: '95%',
                    backgroundColor: theme.palette.background.default,
                    color: theme.palette.text.primary,
                    backgroundImage: 'none'
                }}
            >
                <div id="backButton">{shouldShowBackButton ? <ChevronLeftIcon onClick={handleBackClick} style={{ color: theme.palette.text.primary }} /> : <div id="buttonBack"></div>}</div>
                <Link to="/sessions">
                    <img className="logo" src={logoSignEval} alt="logo" />
                </Link>
                <DrawerMenu />
            </AppBar>
        </div>
    );
};

export default HeaderBar;
