import React from 'react';
import { useTheme } from '../hooks/useTheme';
import { Box } from '@mui/material';

interface ContentInfoProps {
    children: React.ReactNode;
    flexDirection?: string;
    justifyContent?: string;
    margin?: string;
    padding?: string;
}

const ContentInfo: React.FC<ContentInfoProps> = ({ children, flexDirection = 'column', justifyContent = 'space-between', margin = '0 0 1.25em 0', padding = '1.25em 0' }) => {
    const theme = useTheme();

    const contentBgColor = theme.palette.mode === 'light' ? '#FFFFFF' : '#3F4C59';
    const contentBoxShadow = theme.palette.mode === 'light' ? '0px 3px 7px 0px rgba(0,0,0,0.15)' : 'none';

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection,
                justifyContent,
                alignItems: 'center',
                width: '100%',
                margin: margin,
                backgroundColor: contentBgColor,
                boxShadow: contentBoxShadow,
                borderRadius: '10px',
                padding: padding
            }}
        >
            {children}
        </Box>
    );
};

export default ContentInfo;
