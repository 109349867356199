import { format } from 'date-fns';

// Types
import { RegistrationData } from '../../types/registrationTypes';
import { requestHeaders } from './requestHeaders';

// Function to sign up a user
export const signUp = async (registrationData: RegistrationData): Promise<boolean> => {
    try {
        const response = await fetch((process.env.REACT_APP_API_URL as string) + '/groups/signeval', {
            method: 'POST',
            headers: requestHeaders,
            body: JSON.stringify(registrationData)
        });

        if (response.ok) {
            return true;
        } else {
            return false;
        }
    } catch (error) {
        console.error('Error signing up:', error);
        return false;
    }
};
