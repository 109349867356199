import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import i18n from '../../i18n/i18n';
import { Link } from 'react-router-dom';
import { useTheme } from '../../hooks/useTheme';

// Styles
import '../../styles/passwordForgotten.css';

// Components
import SignEvalTextField from '../../components/SignEvalTextField';
import SignEvalButton from '../../components/SignEvalButton';

//Images
import logoSignEval from '../../assets/images/logoSignEval.png';

// MUI imports
import MailOutlineIcon from '@mui/icons-material/MailOutline';

type FormValues = {
    email: string;
};

const PasswordForgotten: React.FC = () => {
    const theme = useTheme();
    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm<FormValues>();

    const onSubmit: SubmitHandler<FormValues> = async (data) => {
        console.log(data); // Sera ensuite supprimé
        // Logic to handle form submission
    };

    return (
        <div className="passwordForgotten" style={{ backgroundColor: theme.palette.background.default, color: theme.palette.text.primary }}>
            <img id="logo" src={logoSignEval} alt="logo" className="logo" />
            <p id="title" style={{ color: theme.palette.text.primary }}>
                {i18n.t('PasswordForgotten.description')}
            </p>
            <form id="formPasswordForgotten">
                <SignEvalTextField
                    icon={<MailOutlineIcon />}
                    iconPosition="end"
                    registerProps={{
                        ...register('email', {
                            required: true,
                            pattern: {
                                value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/,
                                message: i18n.t('Login.error.emailPattern')
                            }
                        })
                    }}
                    id="email"
                    label={i18n.t('Login.placeholder.email') + '*'}
                    type="email"
                />
                {errors.email && <p className="error">{i18n.t('Login.error.emailPattern')}</p>}
            </form>
            <div id="logActions">
                <SignEvalButton size="large" backgroundColor="blue" onClick={handleSubmit(onSubmit)}>
                    {i18n.t('PasswordForgotten.button')}
                </SignEvalButton>
                <div id="signUpActions">
                    <p id="noAccount" style={{ color: theme.palette.text.primary }}>
                        {i18n.t('Login.withoutAccount')}
                    </p>
                    <Link to="/signup-info">
                        <p id="signUpButton" style={{ color: theme.palette.text.primary }}>
                            {i18n.t('Login.link.signUp')}
                        </p>
                    </Link>
                </div>
                <Link to="/">
                    <p id="passwordForgotten" style={{ color: theme.palette.text.primary }}>
                        {i18n.t('Login.link.logIn')}
                    </p>
                </Link>
            </div>
        </div>
    );
};

export default PasswordForgotten;
