// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.92.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.92.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.connection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    height: 100vh;
}

#logo {
    width: 10em;
    height: auto;
    margin-bottom: 0.5em;
}

#form {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 70%;
    max-width: 25em;
    height: 20%;
    max-height: 9em;
}

.error {
    color: red;
    font-size: 0.75em;
    margin: 0;
}

.logActions {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 70%;
    height: 20%;
    max-height: 9em;
}

.logActions p {
    font-size: 0.8em;
}

#signUpActions {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/styles/styles-components/login.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,iBAAiB;IACjB,kBAAkB;IAClB,WAAW;IACX,aAAa;AACjB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,oBAAoB;AACxB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,6BAA6B;IAC7B,mBAAmB;IACnB,UAAU;IACV,eAAe;IACf,WAAW;IACX,eAAe;AACnB;;AAEA;IACI,UAAU;IACV,iBAAiB;IACjB,SAAS;AACb;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,6BAA6B;IAC7B,mBAAmB;IACnB,UAAU;IACV,WAAW;IACX,eAAe;AACnB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,8BAA8B;IAC9B,mBAAmB;IACnB,WAAW;AACf","sourcesContent":[".connection {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    margin-left: auto;\n    margin-right: auto;\n    width: 100%;\n    height: 100vh;\n}\n\n#logo {\n    width: 10em;\n    height: auto;\n    margin-bottom: 0.5em;\n}\n\n#form {\n    display: flex;\n    flex-direction: column;\n    justify-content: space-evenly;\n    align-items: center;\n    width: 70%;\n    max-width: 25em;\n    height: 20%;\n    max-height: 9em;\n}\n\n.error {\n    color: red;\n    font-size: 0.75em;\n    margin: 0;\n}\n\n.logActions {\n    display: flex;\n    flex-direction: column;\n    justify-content: space-around;\n    align-items: center;\n    width: 70%;\n    height: 20%;\n    max-height: 9em;\n}\n\n.logActions p {\n    font-size: 0.8em;\n}\n\n#signUpActions {\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n    align-items: center;\n    width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
