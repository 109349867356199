import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import i18n from '../../i18n/i18n';
import { Link } from 'react-router-dom';

// Styles
import '../../styles/styles-components/signUp.css';

// Components
import SignEvalTextField from '../../components/SignEvalTextField';
import SignEvalButton from '../../components/SignEvalButton';

// Images
import logoSignEval from '../../assets/images/logoSignEval.png';

// MUI imports
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { blue, green, red } from '@mui/material/colors';
import Fab from '@mui/material/Fab';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

// Helpers
import { useTheme } from '../../hooks/useTheme';
import { useRegistrationContext } from '../../hooks/useRegistrationContext';
import { signUp } from '../../helpers/api/callAuthentification';

type FormValues = {
    password: string;
    confirmPassword: string;
};

const SignUpPasswords = () => {
    const theme = useTheme();
    const { registrationData } = useRegistrationContext();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isFailed, setIsFailed] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    const handleOpen = () => setIsOpen(true);
    const handleClose = () => setIsOpen(false);
    const minLength = 10;

    const bgLoarderColor = theme.palette.mode === 'dark' ? '#15202B' : '#FFFFFF';
    const dropBoxColor = theme.palette.mode === 'dark' ? '#212A32' : 'default-border-color';

    const buttonSx = {
        bgcolor: isSuccess ? green[500] : isFailed ? red[500] : bgLoarderColor,
        color: 'white',
        boxShadow: 'none'
    };

    const {
        register,
        handleSubmit,
        formState: { errors },
        watch
    } = useForm<FormValues>({ mode: 'onTouched' });

    const handleSignUp: SubmitHandler<FormValues> = async (data) => {
        setIsSubmitting(true);
        setIsLoading(true);

        const result = await signUp({ ...registrationData, password: data.password });

        setIsLoading(false);
        true === result ? setIsSuccess(true) : setIsFailed(true);
    };

    const password = watch('password') || '';

    if (!isSubmitting) {
        return (
            <div id="signUp" style={{ backgroundColor: theme.palette.background.default }}>
                <img id="logo" src={logoSignEval} alt="logo" className="logo" />
                <p id="signUpTitle" style={{ color: theme.palette.text.primary }}>
                    {i18n.t('Login.PasswordTitle')}
                </p>
                <form className={!isOpen ? 'formSignUpPasswords' : 'formSignUpPasswordsOpen'}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '0.2em', width: '100%', justifyContent: 'center' }}>
                        <SignEvalTextField
                            onClick={handleOpen}
                            icon={<LockOutlinedIcon />}
                            iconPosition="end"
                            registerProps={{
                                ...register('password', {
                                    required: true,
                                    pattern: {
                                        value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W)[a-zA-Z\d\W]{8,}$/,
                                        message: i18n.t('Login.error.password')
                                    }
                                })
                            }}
                            label={i18n.t('Login.placeholder.password')}
                            error={!!errors.password}
                            type="password"
                        />
                        {isOpen && (
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    width: '100%',
                                    height: '9.5em',
                                    color: theme.palette.text.primary,
                                    borderRadius: '10px',
                                    alignItems: 'center',
                                    boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)',
                                    bgcolor: dropBoxColor
                                }}
                            >
                                <LinearProgress
                                    variant="determinate"
                                    value={Math.min((password.length * 100) / minLength, 100)}
                                    sx={{
                                        width: '95%',
                                        height: '0.7em',
                                        marginTop: '0.5em',
                                        bgcolor: 'background.level3',
                                        color: theme.palette.text.custom,
                                        borderRadius: '50px'
                                    }}
                                />
                                <Typography component="span" variant="body2" sx={{ fontSize: '0.8em', marginRight: '0.5em', marginTop: '0.1em' }}>
                                    {password.length < 3 && i18n.t('Login.passwordStrength.0')}
                                    {password.length >= 3 && password.length < 5 && i18n.t('Login.passwordStrength.1')}
                                    {password.length >= 5 && password.length < 7 && i18n.t('Login.passwordStrength.2')}
                                    {password.length >= 7 && i18n.t('Login.passwordStrength.3')}
                                </Typography>
                                <Divider sx={{ width: '100%', marginTop: '0.5em' }} />
                                <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'center', padding: '0.5em' }}>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '0.5em', alignItems: 'center', fontSize: '0.85em' }}>
                                        {password.length >= 8 ? <CheckIcon sx={{ color: 'green' }} /> : <CloseIcon sx={{ color: 'red' }} />}
                                        {i18n.t('Login.passwordRequirements.length')}
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '0.5em', alignItems: 'center', fontSize: '0.85em' }}>
                                        {password.match(/[A-Z]/) ? <CheckIcon sx={{ color: 'green' }} /> : <CloseIcon sx={{ color: 'red' }} />}
                                        {i18n.t('Login.passwordRequirements.uppercase')}
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '0.5em', alignItems: 'center', fontSize: '0.85em' }}>
                                        {password.match(/\d/) ? <CheckIcon sx={{ color: 'green' }} /> : <CloseIcon sx={{ color: 'red' }} />}
                                        {i18n.t('Login.passwordRequirements.number')}
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '0.5em', alignItems: 'center', fontSize: '0.85em' }}>
                                        {password.match(/\W/) ? <CheckIcon sx={{ color: 'green' }} /> : <CloseIcon sx={{ color: 'red' }} />}
                                        {i18n.t('Login.passwordRequirements.special')}
                                    </Box>
                                </Box>
                            </Box>
                        )}
                    </Box>
                    <SignEvalTextField
                        onClick={handleClose}
                        icon={<LockOutlinedIcon />}
                        iconPosition="end"
                        registerProps={{
                            ...register('confirmPassword', {
                                required: true,
                                validate: (value) => value === password || i18n.t('Login.error.passwordMatch')
                            })
                        }}
                        label={i18n.t('Login.placeholder.confirmPassword')}
                        error={!!errors.confirmPassword}
                        type="password"
                    />
                    {errors.password && <p className="error">{i18n.t('Login.error.password')}</p>}
                    {errors.confirmPassword && !errors.password && <p className="error">{i18n.t('Login.error.passwordMatch')}</p>}
                </form>
                <div id="actions">
                    <SignEvalButton size="large" backgroundColor="blue" onClick={handleSubmit(handleSignUp)}>
                        {i18n.t('Login.link.signUp')}
                    </SignEvalButton>
                    <div id="actionsLogin">
                        <p style={{ color: theme.palette.text.primary }}>{i18n.t('Login.withAccount')}</p>
                        <Link id="signUpButton" to="/">
                            <p style={{ color: theme.palette.text.primary }}>{i18n.t('Login.link.logIn')}</p>
                        </Link>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div id="signUp" style={{ backgroundColor: theme.palette.background.default }}>
            <img id="logo" src={logoSignEval} alt="logo" className="logo" />
            <div id="loader">
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ m: 1, position: 'relative' }}>
                        <Fab sx={buttonSx}>{isSuccess ? <CheckIcon /> : isFailed ? <CloseIcon /> : null}</Fab>
                        {isLoading && (
                            <CircularProgress
                                size={68}
                                sx={{
                                    color: blue[400],
                                    position: 'absolute',
                                    top: -6,
                                    left: -6,
                                    zIndex: 1
                                }}
                            />
                        )}
                    </Box>
                </Box>
                {isSuccess && (
                    <>
                        <div className="loaderMessage" style={{ color: theme.palette.text.primary }}>
                            <p>{i18n.t('Login.loader.successMessage')}</p>
                        </div>
                        <SignEvalButton id="loginButton" size="large" backgroundColor="blue" onClick={() => window.location.replace('/')}>
                            {i18n.t('Login.link.logIn')}
                        </SignEvalButton>
                    </>
                )}

                {isFailed && (
                    <>
                        <div className="loaderMessage" style={{ color: theme.palette.text.primary }}>
                            <p>{i18n.t('Login.loader.failedMessage')}</p>
                        </div>
                        <SignEvalButton id="loginButton" size="large" backgroundColor="blue" onClick={() => window.location.replace('/signup-info')}>
                            {i18n.t('Login.link.retry')}
                        </SignEvalButton>
                    </>
                )}

                {!isSuccess && !isFailed && (
                    <div className="loaderMessage" style={{ color: theme.palette.text.primary }}>
                        <p>{i18n.t('Login.loader.loadingMessage')}</p>
                        <p>{i18n.t('Login.loader.pleaseWait')}</p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default SignUpPasswords;
