import { format } from 'date-fns';
import { requestHeaders } from './requestHeaders';

export const getAttendances = async (sessionId: string | undefined, internId: string, slotId: string): Promise<boolean> => {
    try {
        const attendanceResponse = await fetch((process.env.REACT_APP_API_URL as string) + '/attendances?id=' + sessionId, {
            method: 'GET',
            headers: requestHeaders
        });

        const slotResponse = await fetch((process.env.REACT_APP_API_URL as string) + '/slots?id=' + slotId, {
            method: 'GET',
            headers: requestHeaders
        });

        if (!attendanceResponse.ok) {
            throw new Error('Failed to fetch attendance data');
        }

        if (!slotResponse.ok) {
            throw new Error('Failed to fetch slot data');
        }

        const attendances = (await attendanceResponse.json()).data;
        const slot = (await slotResponse.json()).data[0];

        const formattedStartTime = format(new Date(slot.startDate), 'HH:mm');
        const formattedEndTime = format(new Date(slot.endDate), 'HH:mm');

        for (const attendance of attendances) {
            if (format(new Date(attendance.date), 'yyyy-MM-dd') !== format(new Date(slot.startDate), 'yyyy-MM-dd')) {
                continue;
            }

            if (attendance.startTime !== formattedStartTime || attendance.endTime !== formattedEndTime) {
                continue;
            }

            if (attendance.users.length === 0) {
                continue;
            }

            for (const user of attendance.users) {
                if (user.id == internId) {
                    return true;
                }
            }
        }

        return false;
    } catch (error) {
        console.error('Error checking if intern is signed:', error);
        return false;
    }
};

export const getInternsSigned = async (sessionId: string | undefined, slotId: string): Promise<number> => {
    try {
        const attendanceResponse = await fetch((process.env.REACT_APP_API_URL as string) + '/attendances?id=' + sessionId, {
            method: 'GET',
            headers: requestHeaders
        });

        const slotResponse = await fetch((process.env.REACT_APP_API_URL as string) + '/slots?id=' + slotId, {
            method: 'GET',
            headers: requestHeaders
        });

        if (!attendanceResponse.ok) {
            throw new Error('Failed to fetch attendance data');
        }

        if (!slotResponse.ok) {
            throw new Error('Failed to fetch slot data');
        }

        const attendances = (await attendanceResponse.json()).data;
        const slot = (await slotResponse.json()).data[0];

        const formattedStartTime = format(new Date(slot.startDate), 'HH:mm');
        const formattedEndTime = format(new Date(slot.endDate), 'HH:mm');

        for (const attendance of attendances) {
            if (format(new Date(attendance.date), 'yyyy-MM-dd') !== format(new Date(slot.startDate), 'yyyy-MM-dd')) {
                continue;
            }

            if (attendance.startTime !== formattedStartTime || attendance.endTime !== formattedEndTime) {
                continue;
            }

            if (attendance.users.length === 0) {
                continue;
            }

            return attendance.users.length;
        }

        return 0;
    } catch (error) {
        console.error('Error getting how many interns are signed:', error);
        return 0;
    }
};
