import { createContext, useState, useEffect, ReactNode } from 'react';

type ThemeContextType = {
    currentTheme: string;
    toggleTheme: () => void;
};

const ThemeContext = createContext<ThemeContextType>({
    currentTheme: 'light',
    toggleTheme: () => {
        // Fonction vide par défaut
        // Implémentation vide par défaut
    }
});

const STORAGE_THEMEMODE = 'theme';
const STORAGE_CHAKRATHEMEMODE = 'chakra-ui-color-mode';

function ThemeProvider({ children }: { children: ReactNode }) {
    const [theme, setTheme] = useState<string>(localStorage.getItem(STORAGE_THEMEMODE) || 'light');
    const [chakraTheme, setChakraTheme] = useState<string>(localStorage.getItem(STORAGE_CHAKRATHEMEMODE) || 'light');

    useEffect(() => {
        localStorage.setItem(STORAGE_THEMEMODE, theme);
        localStorage.setItem(STORAGE_CHAKRATHEMEMODE, chakraTheme);
    }, [theme]);

    const toggleTheme = () => {
        setTheme((prevTheme) => (prevTheme === 'dark' ? 'light' : 'dark'));
        setChakraTheme((prevChakraTheme) => (prevChakraTheme === 'dark' ? 'light' : 'dark'));
    };

    return <ThemeContext.Provider value={{ currentTheme: theme, toggleTheme }}>{children}</ThemeContext.Provider>;
}

export { ThemeProvider, ThemeContext };
