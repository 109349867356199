import React, { createContext, useState, ReactNode } from 'react';
import { RegistrationData } from '../types/registrationTypes';

type RegistrationContextType = {
    registrationData: RegistrationData;
    setRegistrationData: (data: RegistrationData) => void;
};

const RegistrationContext = createContext<RegistrationContextType | undefined>(undefined);

type RegistrationProviderProps = {
    children: ReactNode;
};

const RegistrationProvider = ({ children }: RegistrationProviderProps) => {
    const [registrationData, setRegistrationData] = useState<RegistrationData>({
        label: '',
        firstname: '',
        lastname: '',
        email: '',
        phoneNumber: '',
        password: ''
    });

    return <RegistrationContext.Provider value={{ registrationData, setRegistrationData }}>{children}</RegistrationContext.Provider>;
};

export { RegistrationProvider };
export default RegistrationContext;
