import React from 'react';
import { Button, ButtonProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';

const colors: Record<string, string> = {
    red: '#FF6642',
    purple: '#6979DF',
    green: '#36D476',
    blue: '#5BB3D9',
    orange: '#FFB664',
    white: '#FFFFFF'
};

const buttonWidths: Record<string, string> = {
    small: '30%',
    medium: '47.5%',
    large: '100%'
};

const justifyContents: Record<string, string> = {
    small: 'center',
    medium: 'center',
    large: 'space-around'
};

interface SignEvalButtonProps extends Omit<ButtonProps, 'size'> {
    size: 'small' | 'medium' | 'large';
    backgroundColor: keyof typeof colors;
    route?: string;
}

const SignEvalStyled = styled(Button)<{ size: 'small' | 'medium' | 'large'; backgroundcolor: string }>(({ size, backgroundcolor, theme }) => ({
    color: theme.palette.text.secondary,
    maxWidth: '25em',
    maxHeight: '3em',
    borderRadius: '10px',
    fontSize: '1rem',
    fontWeight: '500',
    boxShadow: '0px 3px 7px 0px rgba(0,0,0,0.15)',
    textTransform: 'none',
    backgroundColor: colors[backgroundcolor],
    width: buttonWidths[size],
    justifyContent: justifyContents[size],

    '&:hover': {
        backgroundColor: colors[backgroundcolor]
    },

    '& p': {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%'
    }
}));

const SignEvalButton: React.FC<SignEvalButtonProps> = ({ children, backgroundColor, size, route, ...props }) => {
    if (route) {
        return (
            <Link to={route} style={{ display: 'flex', justifyContent: 'center', width: buttonWidths[size], textDecoration: 'none' }}>
                <SignEvalStyled backgroundcolor={backgroundColor} size={size} {...props}>
                    {children}
                </SignEvalStyled>
            </Link>
        );
    } else {
        return (
            <SignEvalStyled backgroundcolor={backgroundColor} size={size} {...props}>
                {children}
            </SignEvalStyled>
        );
    }
};

export default SignEvalButton;
