import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import { Language } from '../language';
import translation_en_EN from './en-EN.json';
import translation_fr_FR from './fr-FR.json';

const resources = {
    fr: {
        translation: translation_fr_FR
    },
    en: {
        translation: translation_en_EN
    }
};

i18n.use(LanguageDetector).use(initReactI18next).init({
    fallbackLng: Language.fr_FR,
    resources
});

export default i18n;
