import { useState } from 'react';

// Translations
import i18n from '../../i18n/i18n';

// Helpers
import { useTheme } from '../../hooks/useTheme';

// Components
import HeaderBar from '../../components/HeaderBar';
import Avatar from '../../components/Avatar';
import SignEvalButton from '../../components/SignEvalButton';
import SignEvalTextField from '../../components/SignEvalTextField';
import ContentInfo from '../../components/ContentInfo';
import TitleContentInfo from '../../components/TitleContentInfo';

// MUI imports
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

// MUI icons
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

// Chakra-UI imports
import { Collapse } from '@chakra-ui/react';

const Account = () => {
    const theme = useTheme();
    const [showUpdatePassword, setShowUpdatePassword] = useState(false);

    const handleShowUpdatePassword = () => {
        setShowUpdatePassword(!showUpdatePassword);
    };

    const CollapseStyled = styled(Collapse)({
        height: 'auto',
        boxShadow: theme.palette.mode === 'light' ? '0px 3px 7px 0px rgba(0,0,0,0.15)' : 'none',
        backgroundColor: theme.palette.mode === 'light' ? '#FFFFFF' : '#3F4C59',
        borderRadius: '10px',
        marginBottom: '1.25em'
    });

    return (
        <Box
            id="account"
            sx={{
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: theme.palette.background.default,
                color: theme.palette.text.primary,
                width: '100%',
                height: '100vh',
                alignItems: 'center'
            }}
        >
            <HeaderBar />
            <Box sx={{ marginTop: '0.5em', width: '91vw' }}>
                <ContentInfo flexDirection="column" justifyContent="space-between">
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '85%', margin: '0 auto' }}>
                        <Typography sx={{ color: theme.palette.secondary.main, fontSize: '1.6em', fontWeight: '600' }}>Nom Prénom</Typography>
                        <Avatar firstName="Amélie" lastName="Besse" size="large" />
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', width: '85%' }}>
                        <Typography sx={{ color: theme.palette.text.primary, fontSize: '1.1em', fontWeight: '400', marginTop: '0.75em' }}>Quizzbox Solutions</Typography>
                        <Typography sx={{ color: theme.palette.text.primary, fontSize: '1.1em', fontWeight: '400', marginTop: '0.75em' }}>prenom.nom@quizzbox.com</Typography>
                        <Typography sx={{ color: theme.palette.text.primary, fontSize: '1.1em', fontWeight: '400', marginTop: '0.75em' }}>06 12 34 56 78</Typography>
                    </Box>
                </ContentInfo>
                <Box>
                    <TitleContentInfo title={i18n.t('Account.titleCenter')} />
                    <ContentInfo>
                        <Stack direction="row" spacing={1}>
                            <Avatar firstName="Nom" lastName="Prénom" size="small" />
                            <Avatar firstName="Corentin" lastName="Pimentel" size="small" />
                            <Avatar firstName="Thibault" lastName="Lafaurie" size="small" />
                        </Stack>
                    </ContentInfo>
                </Box>
                <SignEvalButton variant="contained" backgroundColor="blue" size="large" sx={{ marginBottom: '1.25em' }}>
                    {i18n.t('Account.actions.updateAccount')}
                </SignEvalButton>
                <SignEvalButton variant="contained" backgroundColor="blue" size="large" onClick={handleShowUpdatePassword} sx={{ marginBottom: '1.25em' }}>
                    {i18n.t('Account.actions.updatePassword')}
                </SignEvalButton>
                <CollapseStyled in={showUpdatePassword}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            width: '100%',
                            backgroundColor: theme.palette.mode === 'light' ? '#FFFFFF' : '#3F4C59',
                            borderRadius: '10px',
                            padding: '1em 0'
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                width: '92.5%',
                                marginBottom: '0.5em',
                                marginLeft: 'auto',
                                marginRight: 'auto',
                                color: theme.palette.secondary.main
                            }}
                        >
                            <p>{i18n.t('Account.actions.updatePassword')}</p>
                            <CloseOutlinedIcon onClick={handleShowUpdatePassword} />
                        </Box>
                        <SignEvalTextField
                            className="content"
                            icon={<LockOutlinedIcon />}
                            iconPosition="end"
                            label={i18n.t('UpdatePassword.oldPassword')}
                            type="text"
                            sx={{ width: '95%', marginBottom: '1em', marginLeft: 'auto', marginRight: 'auto' }}
                        />
                        <SignEvalTextField
                            className="content"
                            icon={<LockOutlinedIcon />}
                            iconPosition="end"
                            label={i18n.t('UpdatePassword.newPassword')}
                            type="password"
                            sx={{ width: '95%', marginBottom: '1em', marginLeft: 'auto', marginRight: 'auto' }}
                        />
                        <SignEvalTextField
                            className="content"
                            icon={<LockOutlinedIcon />}
                            iconPosition="end"
                            label={i18n.t('UpdatePassword.confirmNewPassword')}
                            type="password"
                            sx={{ width: '95%', marginBottom: '1em', marginLeft: 'auto', marginRight: 'auto' }}
                        />
                        <SignEvalButton className="content" variant="contained" backgroundColor="blue" size="large" sx={{ width: '95%', marginLeft: 'auto', marginRight: 'auto' }}>
                            {i18n.t('Account.actions.updatePassword')}
                        </SignEvalButton>
                    </Box>
                </CollapseStyled>
            </Box>
        </Box>
    );
};

export default Account;
