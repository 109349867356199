import { useContext } from 'react';
import RegistrationContext from '../contexts/registrationContext';

export const useRegistrationContext = () => {
    const context = useContext(RegistrationContext);
    if (undefined === context) {
        throw new Error('useRegistrationContext must be used within a RegistrationProvider');
    }
    return context;
};
