import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import i18n from '../../i18n/i18n';
import { Link, useNavigate } from 'react-router-dom';
import { useTheme } from '../../hooks/useTheme';
import { useRegistrationContext } from '../../hooks/useRegistrationContext';
import { ChakraProvider } from '@chakra-ui/react';
import { RegistrationData } from '../../types/registrationTypes';

// Styles
import '../../styles/styles-components/signUp.css';

// Components
import SignEvalTextField from '../../components/SignEvalTextField';
import SignEvalButton from '../../components/SignEvalButton';
import { ChakraPhone } from '../../components/ChakraPhone';

// Images
import logoSignEval from '../../assets/images/logoSignEval.png';

// MUI imports
import HomeWorkOutlinedIcon from '@mui/icons-material/HomeWorkOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import MailOutlinedIcon from '@mui/icons-material/MailOutlined';

type FormValues = RegistrationData;

const SignUpInfo: React.FC = () => {
    const theme = useTheme();

    const navigate = useNavigate();
    const [phoneValue, setPhoneValue] = React.useState<string>('');
    const [phoneError, setPhoneError] = React.useState<string>('');

    const { setRegistrationData } = useRegistrationContext();

    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm<FormValues>({
        mode: 'onChange'
    });

    const onSubmit: SubmitHandler<FormValues> = async (data) => {
        data.password = '';
        data.phoneNumber = phoneValue;
        if (phoneValue === '') {
            setPhoneError(i18n.t('Login.PhoneRequired'));
            return;
        }
        setRegistrationData(data);
        navigate('/signup-passwords');
    };

    return (
        <div id="signUp" style={{ backgroundColor: theme.palette.background.default }}>
            <img id="logo" src={logoSignEval} alt="logo" className="logo" />
            <form id="formSignUpInfo">
                <SignEvalTextField
                    icon={<HomeWorkOutlinedIcon />}
                    iconPosition="end"
                    registerProps={{
                        ...register('label', {
                            required: true,
                            pattern: {
                                value: /^[a-zA-ZÀ-ÿ0-9\s]{3,}$/,
                                message: i18n.t('Login.error.trainingCenterPattern')
                            }
                        })
                    }}
                    id="trainingCenter"
                    label={i18n.t('Login.placeholder.trainingCenter') + '*'}
                    error={!!errors.label}
                    type="text"
                />
                <SignEvalTextField
                    icon={<HomeWorkOutlinedIcon />}
                    iconPosition="end"
                    registerProps={{
                        ...register('siret', {
                            required: false,
                            pattern: {
                                value: /^[0-9]{14}$/,
                                message: i18n.t('Login.error.siretNumberPattern')
                            }
                        })
                    }}
                    id="siretNumber"
                    label={i18n.t('Login.placeholder.siretNumber')}
                    error={!!errors.siret}
                    type="number"
                />
                <SignEvalTextField
                    icon={<AccountCircleOutlinedIcon />}
                    iconPosition="end"
                    registerProps={{
                        ...register('firstname', {
                            required: true,
                            pattern: {
                                value: /^[a-zA-ZÀ-ÿ\s]{3,}$/,
                                message: i18n.t('Login.error.firstNamePattern')
                            }
                        })
                    }}
                    id="firstName"
                    label={i18n.t('Login.placeholder.firstName') + '*'}
                    error={!!errors.firstname}
                    type="text"
                />
                <SignEvalTextField
                    icon={<AccountCircleOutlinedIcon />}
                    iconPosition="end"
                    registerProps={{
                        ...register('lastname', {
                            required: true,
                            pattern: {
                                value: /^[a-zA-ZÀ-ÿ\s]{3,}$/,
                                message: i18n.t('Login.error.lastNamePattern')
                            }
                        })
                    }}
                    id="lastName"
                    label={i18n.t('Login.placeholder.lastName') + '*'}
                    error={!!errors.lastname}
                    type="text"
                />
                <SignEvalTextField
                    icon={<MailOutlinedIcon />}
                    iconPosition="end"
                    registerProps={{
                        ...register('email', {
                            required: true,
                            pattern: {
                                value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/,
                                message: i18n.t('Login.error.emailPattern')
                            }
                        })
                    }}
                    id="email"
                    label={i18n.t('Login.placeholder.email') + '*'}
                    error={!!errors.email}
                    type="email"
                />
                <ChakraProvider>
                    <ChakraPhone value={phoneValue} onChange={setPhoneValue} />
                </ChakraProvider>
                {errors.label && <p className="error">{i18n.t('Login.error.trainingCenterPattern')}</p>}
                {errors.siret && !errors.label && <p className="error">{i18n.t('Login.error.siretNumberPattern')}</p>}
                {errors.firstname && !errors.label && !errors.siret && <p className="error">{i18n.t('Login.error.firstNamePattern')}</p>}
                {errors.lastname && !errors.label && !errors.siret && !errors.firstname && <p className="error">{i18n.t('Login.error.lastNamePattern')}</p>}
                {errors.email && !errors.label && !errors.siret && !errors.firstname && !errors.lastname && <p className="error">{i18n.t('Login.error.emailPattern')}</p>}
                {phoneError && <p className="error">{phoneError}</p>}
                <p id="RequiredFields" style={{ color: theme.palette.text.primary }}>
                    {'*' + i18n.t('Login.RequiredFields')}
                </p>
            </form>
            <div id="actions">
                <SignEvalButton size="large" backgroundColor="blue" onClick={handleSubmit(onSubmit)}>
                    {i18n.t('Login.link.next')}
                </SignEvalButton>
                <div id="actionsLogin">
                    <p style={{ color: theme.palette.text.primary }}>{i18n.t('Login.withAccount')}</p>
                    <Link id="signUpButton" to="/">
                        <p style={{ color: theme.palette.text.primary }}>{i18n.t('Login.link.logIn')}</p>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default SignUpInfo;
