import React from 'react';
import { useTheme } from '../hooks/useTheme';

// MUI imports
import { Box, IconButton } from '@mui/material';
import { styled } from '@mui/system';
import InputBase from '@mui/material/InputBase';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';

const SearchFilterBar: React.FC = () => {
    const theme = useTheme();

    const SearchBarBgColor = theme.palette.mode === 'light' ? '#EBF9FF' : '#3F4C59';

    const Search = styled('div')({
        position: 'relative',
        borderRadius: '10px',
        color: theme.palette.text.primary,
        backgroundColor: SearchBarBgColor,
        marginLeft: 0,
        width: '92.5%',
        display: 'flex',
        alignItems: 'center'
    });

    const SearchIconWrapper = styled('div')({
        padding: '0 16px',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    });

    const StyledInputBase = styled(InputBase)({
        color: 'inherit',
        width: '100%',
        '& .MuiInputBase-input': {
            padding: '8px 8px 8px 0',
            paddingLeft: '0',
            width: '100%',
            '@media (min-width:600px)': {
                width: '12ch',
                '&:focus': {
                    width: '20ch'
                }
            }
        }
    });

    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: '0.25em', mb: '1em' }}>
            <Search>
                <SearchIconWrapper>
                    <SearchOutlinedIcon />
                </SearchIconWrapper>
                <StyledInputBase placeholder="Search…" inputProps={{ 'aria-label': 'search' }} />
                <IconButton aria-label="filter" sx={{ padding: '0 16px', color: theme.palette.text.custom }}>
                    <TuneOutlinedIcon />
                </IconButton>
            </Search>
        </Box>
    );
};

export default SearchFilterBar;
