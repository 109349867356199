import React, { useState } from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import '../../styles/updateAccount.css';
import i18n from '../../i18n/i18n';
import { useTheme } from '../../hooks/useTheme';

// images
import logoSignEval from '../../assets/images/logoSignEval.png';

// Components
import HeaderBar from '../../components/HeaderBar';
import SignEvalTextField from '../../components/SignEvalTextField';
import SignEvalButton from '../../components/SignEvalButton';
import { ChakraPhone } from '../../components/ChakraPhone';

// MUI imports
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';

const UpdateAccount: React.FC = () => {
    const [phoneValue, setPhoneValue] = useState<string>('');
    const theme = useTheme();

    return (
        <div id="contentUpdateAccount" style={{ backgroundColor: theme.palette.background.default, color: theme.palette.text.primary }}>
            <HeaderBar />
            <div id="updateAccount">
                <form id="updateAccountForm">
                    <div id="updateLogo">
                        <img src={logoSignEval} alt="logoSignEval" />
                        <p style={{ color: theme.palette.text.primary }}>{i18n.t('UpdateAccount.image')}</p>
                    </div>
                    <div id="updateInfo">
                        <SignEvalTextField
                            className="input"
                            label={i18n.t('UpdateAccount.firstName')}
                            type="text"
                            icon={<AccountCircleOutlinedIcon />}
                            iconPosition="end"
                            style={{ color: theme.palette.text.primary }}
                        />
                        <SignEvalTextField
                            className="input"
                            label={i18n.t('UpdateAccount.lastName')}
                            type="text"
                            icon={<AccountCircleOutlinedIcon />}
                            iconPosition="end"
                            style={{ color: theme.palette.text.primary, marginBottom: '1em' }}
                        />
                        <ChakraProvider>
                            <ChakraPhone value={phoneValue} onChange={setPhoneValue} />
                        </ChakraProvider>
                    </div>
                </form>
                <SignEvalButton
                    id="validateAccountUpdate"
                    variant="contained"
                    href="#contained-buttons"
                    backgroundColor={theme.palette.text.custom}
                    size="large"
                    style={{ color: theme.palette.text.secondary }}
                >
                    {i18n.t('UpdateAccount.button')}
                </SignEvalButton>
            </div>
        </div>
    );
};

export default UpdateAccount;
