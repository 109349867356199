import React from 'react';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import { useTheme } from '@mui/material/styles';

// Components
import HeaderBar from './HeaderBar';
import SearchFilterBar from './Search&FilterBar';

const SessionsSkeleton = () => {
    const theme = useTheme();

    const sessionsSkeleton = Array.from(new Array(7)).map((_, index) => (
        <Box
            key={index}
            sx={{
                display: 'flex',
                flexDirection: 'row',
                margin: '0 auto',
                width: '92.5%',
                height: '90px',
                padding: '10px 0',
                backgroundColor: theme.palette.background.default,
                borderTop: '0.1em rgba(0, 0, 0, 0.075) solid'
            }}
        >
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', width: '70%' }}>
                <Skeleton variant="text" width="80%" height={50} animation="wave" />
                <Box>
                    <Skeleton variant="text" width="50%" height={25} animation="wave" />
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', fontSize: '16px' }}>
                        <Skeleton variant="text" width="70%" height={25} animation="wave" />
                    </Box>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'flex-end', width: '30%', color: theme.palette.text.custom }}>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', color: theme.palette.text.custom }}>
                    <Skeleton variant="text" width={50} height={40} animation="wave" />
                </Box>
                <Skeleton variant="text" width={90} height={40} animation="wave" />
            </Box>
        </Box>
    ));

    return (
        <Box sx={{ width: '100vw', height: '100vh', alignItems: 'center', backgroundColor: theme.palette.background.default, color: theme.palette.text.primary }}>
            <HeaderBar />
            <SearchFilterBar />
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%' }}>{sessionsSkeleton}</Box>
        </Box>
    );
};

export default SessionsSkeleton;
