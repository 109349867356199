import { format } from 'date-fns';
import { SessionData } from '../../types/sessionData';
import { requestHeaders } from './requestHeaders';

export const getSessions = async (): Promise<Array<SessionData>> => {
    try {
        const sessionResponse = await fetch((process.env.REACT_APP_API_URL as string) + '/sessions', {
            method: 'GET',
            headers: requestHeaders
        });
        const rawSessions = (await sessionResponse.json()).data;

        const responseTrainings = await fetch((process.env.REACT_APP_API_URL as string) + '/trainings', {
            method: 'GET',
            headers: requestHeaders
        });
        const rawTrainings = (await responseTrainings.json()).data;
        const trainingMap = new Map(rawTrainings.map((training: any) => [training.id, training]));

        const sessions = rawSessions.map((session: any) => {
            const trainingInfo: any = trainingMap.get(Number(session.trainingId));
            const beginDate = format(new Date(session.creationDate), 'dd/MM/yyyy');
            const endDate = format(new Date(session.endDate), 'dd/MM/yyyy');

            return {
                id: session.id,
                trainingLabel: trainingInfo ? trainingInfo.label : 'Pas de formation',
                sessionName: session.name,
                sessionBegin: beginDate,
                sessionEnd: endDate,
                trainees: session.traineesId.length,
                sessionToken: session.token
            };
        });
        return sessions;
    } catch (error) {
        console.error('Error fetching sessions:', error);
        return [];
    }
};

export const getSession = async (sessionId: string | undefined): Promise<SessionData | null> => {
    if (!sessionId) {
        console.error('sessionId is null or undefined');
        return null;
    }

    try {
        const responseSession = await fetch((process.env.REACT_APP_API_URL as string) + '/sessions?id=' + sessionId, {
            method: 'GET',
            headers: requestHeaders
        });
        const dataSession = (await responseSession.json()).data[0];

        const responseTraining = await fetch((process.env.REACT_APP_API_URL as string) + '/trainings?id=' + dataSession.trainingId, {
            method: 'GET',
            headers: requestHeaders
        });
        const dataTraining = (await responseTraining.json()).data[0];

        const beginDate = format(new Date(dataSession.creationDate), 'dd/MM/yyyy');
        const endDate = format(new Date(dataSession.endDate), 'dd/MM/yyyy');

        const responseTrainer = await fetch((process.env.REACT_APP_API_URL as string) + '/users?id=' + dataSession.trainerId, {
            method: 'GET',
            headers: requestHeaders
        });
        const dataTrainer = (await responseTrainer.json()).data[0];

        return {
            id: dataSession.id,
            trainingLabel: dataTraining.label,
            sessionToken: dataSession.token,
            sessionName: dataSession.name,
            sessionBegin: beginDate,
            sessionEnd: endDate,
            trainerName: dataTrainer.firstname + ' ' + dataTrainer.lastname,
            trainees: dataSession.traineesId,
            slotsId: dataSession.slotsId
        };
    } catch (error) {
        console.error('Error getting session:', error);
        return null;
    }
};

export const deleteSession = async (sessionsId: string | undefined): Promise<boolean> => {
    if (!sessionsId) {
        console.error('sessionsId is null or undefined');
        return false;
    }

    try {
        const response = await fetch((process.env.REACT_APP_API_URL as string) + '/sessions/' + sessionsId, {
            method: 'DELETE',
            headers: requestHeaders
        });

        if (response.status === 204) {
            return true;
        } else {
            return false;
        }
    } catch (error) {
        console.error('Error deleting session:', error);
        return false;
    }
};
