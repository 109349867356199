// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.92.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.92.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#contentUpdateAccount {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100vh;
}

#updateAccount {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 0 0 30px 0;
}

#updateAccountForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 90%;
    min-height: 17em;
}

#updateLogo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 20%;
}

#updateLogo img {
    width: 40%;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}

#updateLogo p {
    margin-top: 0.8em;
    color: #5bb3d9;
    text-decoration: underline;
}

#updateInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 87.5%;
    height: 70%;
}

#updateInfo .input {
    margin-top: 1em;
}

#validateAccountUpdate {
    width: 87.5%;
}
`, "",{"version":3,"sources":["webpack://./src/styles/updateAccount.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,WAAW;IACX,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,8BAA8B;IAC9B,mBAAmB;IACnB,WAAW;IACX,YAAY;IACZ,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,WAAW;IACX,WAAW;IACX,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,WAAW;IACX,WAAW;AACf;;AAEA;IACI,UAAU;IACV,yCAAyC;AAC7C;;AAEA;IACI,iBAAiB;IACjB,cAAc;IACd,0BAA0B;AAC9B;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,YAAY;IACZ,WAAW;AACf;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,YAAY;AAChB","sourcesContent":["#contentUpdateAccount {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    width: 100%;\n    height: 100vh;\n}\n\n#updateAccount {\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n    align-items: center;\n    width: 100%;\n    height: 100%;\n    padding: 0 0 30px 0;\n}\n\n#updateAccountForm {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    width: 100%;\n    height: 90%;\n    min-height: 17em;\n}\n\n#updateLogo {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    width: 100%;\n    height: 20%;\n}\n\n#updateLogo img {\n    width: 40%;\n    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);\n}\n\n#updateLogo p {\n    margin-top: 0.8em;\n    color: #5bb3d9;\n    text-decoration: underline;\n}\n\n#updateInfo {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    width: 87.5%;\n    height: 70%;\n}\n\n#updateInfo .input {\n    margin-top: 1em;\n}\n\n#validateAccountUpdate {\n    width: 87.5%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
