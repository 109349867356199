import React from 'react';
import Avatar_MUI from '@mui/material/Avatar';
import { styled } from '@mui/material/styles';

interface Props {
    firstName: string;
    lastName: string;
    size: 'small' | 'medium' | 'large';
}

const Avatar: React.FC<Props> = ({ firstName, lastName, size }: Props) => {
    const AvatarMUI = styled(Avatar_MUI)({
        width: size === 'large' ? '3.5em' : size === 'medium' ? '2.75em' : '2.5em',
        height: size === 'large' ? '3.5em' : size === 'medium' ? '2.75em' : '2.5em',
        fontSize: size === 'large' ? '1.35em' : size === 'medium' ? '1.1em' : '0.9em'
    });

    function stringToColor(string: string) {
        let hash = 0;
        let i;

        for (i = 0; i < string.length; i++) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }

        let color = '#';

        for (i = 0; i < 3; i++) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.slice(-2);
        }

        return color;
    }

    function stringAvatar(firstName: string, lastName: string) {
        return {
            sx: {
                bgcolor: stringToColor(`${firstName} ${lastName}`)
            },
            children: `${firstName.split(' ')[0][0]}${lastName.split(' ')[0][0]}`
        };
    }

    return (
        <div>
            <AvatarMUI {...stringAvatar(firstName, lastName)} />
        </div>
    );
};

export default Avatar;
