import react from 'react';
import { MuiColorInput } from 'mui-color-input';

// Languages
import i18n from '../../i18n/i18n';

// Helpers
import { useTheme } from '../../hooks/useTheme';

// Components
import HeaderBar from '../../components/HeaderBar';
import ContentInfo from '../../components/ContentInfo';
import TitleContentInfo from '../../components/TitleContentInfo';

const AttendanceSheet: React.FC = () => {
    const theme = useTheme();

    const [color, setColor] = react.useState<string>('#000000');

    const handleChangeColor = (newColor: string) => {
        setColor(newColor);
    };

    return (
        <div
            id="center"
            style={{
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: theme.palette.background.default,
                color: theme.palette.text.primary,
                width: '100%',
                height: '100vh',
                alignItems: 'center'
            }}
        >
            <HeaderBar />
            <div style={{ marginTop: '0.5em', width: '91vw' }}>
                <div>
                    <TitleContentInfo title={i18n.t('AttendanceSheet.title')} />
                    <ContentInfo flexDirection="column">
                        <div style={{ width: '90%', padding: '0 1em' }}>
                            <h4 style={{ marginBottom: '0.5em', color: theme.palette.text.primary }}>{i18n.t('AttendanceSheet.color')}</h4>
                            <MuiColorInput format="hex" value={color} onChange={handleChangeColor} />
                        </div>
                    </ContentInfo>
                </div>
            </div>
        </div>
    );
};

export default AttendanceSheet;
