import { EnumDescriptor } from './types/enumDescriptor';

export enum Language {
    fr_FR = 'fr_FR',
    en_EN = 'en_EN'
}

export const languageEnumDescriptor: EnumDescriptor[] = [
    {
        key: Language.fr_FR,
        description: 'Français'
    },
    {
        key: Language.en_EN,
        description: 'English'
    }
];
