import React from 'react';
import { useTheme } from '../hooks/useTheme';

interface ContentInfoProps {
    title: string;
}

const ContentInfo: React.FC<ContentInfoProps> = ({ title }) => {
    const theme = useTheme();

    return (
        <div style={{ marginBottom: '0.35em', marginLeft: '1em' }}>
            <p style={{ color: theme.palette.secondary.main, fontSize: '1.05em', fontWeight: '700' }}>{title}</p>
        </div>
    );
};

export default ContentInfo;
