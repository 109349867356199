import { useState, useEffect } from 'react';
import i18n from '../i18n/i18n';
import React from 'react';
import { useTheme } from '../hooks/useTheme';

// Components imports
import HeaderBar from '../components/HeaderBar';
import SearchFilterBar from '../components/Search&FilterBar';
import SessionsSkeleton from '../components/SessionsSkeleton';
import Session from '../components/Session';

// MUI imports
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';

// Helpers
import { getSessions } from '../helpers/api/callSession';

// Types
import { SessionData } from '../types/sessionData';

const Sessions: React.FC = () => {
    const theme = useTheme();

    const [sessionList, setSessionList] = useState<Array<SessionData>>([]);
    const [isLoading, setIsLoading] = useState(false);
    const smallScreen = useMediaQuery('(max-height:700px)');

    useEffect(() => {
        const fetchSessions = async () => {
            try {
                setIsLoading(true);
                const sessions = await getSessions();
                setSessionList(sessions);
            } catch (error) {
                console.error('Error fetching sessions:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchSessions();
    }, []);

    if (isLoading) return <SessionsSkeleton />;

    if (sessionList.length === 0)
        return (
            <Box sx={{ alignItems: 'center', backgroundColor: theme.palette.background.default, color: theme.palette.text.primary }}>
                <HeaderBar />
                <SearchFilterBar />
                <Typography id="noSession" sx={{ color: theme.palette.text.primary }}>
                    {i18n.t('Sessions.errors.errorNoSessionsRunning')}
                </Typography>
            </Box>
        );

    return (
        <Box sx={{ alignItems: 'center', backgroundColor: theme.palette.background.default, color: theme.palette.text.primary }}>
            <HeaderBar />
            <SearchFilterBar />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    height: smallScreen ? 'calc(100vh - 6vh - 6vh - 3em)' : 'calc(100vh - 6vh - 6vh - 1.3em)',
                    overflowY: 'auto'
                }}
            >
                {sessionList.map((session) => (
                    <Session key={session.id} session={session} />
                ))}
            </Box>
        </Box>
    );
};

export default Sessions;
