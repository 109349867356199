import { useEffect, useState } from 'react';
import { useTheme } from '../hooks/useTheme';
import { useParams } from 'react-router-dom';

// Components
import Avatar from './Avatar';
import SignButton from './SignButton';
import EvaluateButton from './EvaluateButton';

// MUI imports
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

// Helpers
import { getIntern } from '../helpers/api/callIntern';

// Types
import { InternData } from '../types/internData';
import { SlotData } from '../types/slotData';

interface InternsProps {
    trainees: Array<string>;
    todaySlot: SlotData;
}

const InternsComponent = ({ trainees, todaySlot }: InternsProps) => {
    const theme = useTheme();
    const { id } = useParams<{ id: string }>();
    const [interns, setInterns] = useState<InternData[]>([]);

    const borderColor = theme.palette.mode === 'light' ? '#EFEFEF' : '#303234';

    useEffect(() => {
        const fetchInterns = async () => {
            const internDataPromises = trainees.map(async (internId) => {
                const internData = await getIntern(internId);
                return internData ? { ...internData, id: internId } : null;
            });

            const internsData = await Promise.all(internDataPromises);
            setInterns(internsData.filter((intern) => intern !== null) as InternData[]);
        };

        fetchInterns();
    }, [trainees]);

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', height: '55vh', overflow: 'scroll' }}>
            {interns.map((intern) => (
                <Box
                    key={intern.id}
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: '100%',
                        padding: '7.5px 0',
                        color: theme.palette.secondary.main,
                        backgroundColor: theme.palette.background.default,
                        borderTop: `1px solid ${borderColor}`
                    }}
                >
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <Avatar firstName={intern.firstname} lastName={intern.lastname} size="medium" />
                        <Typography variant="subtitle1" sx={{ marginLeft: '1em' }}>
                            {intern.firstname} {intern.lastname}
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <SignButton route="/interns/sign" internId={intern.id} sessionId={id} todaySlot={todaySlot} />
                        <EvaluateButton route="/interns/sign" internId={intern.id} sessionId={id} />
                    </Box>
                </Box>
            ))}
        </Box>
    );
};

export default InternsComponent;
