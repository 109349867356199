import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import i18n from '../../i18n/i18n';
import { Link, useNavigate } from 'react-router-dom';
import { useTheme } from '../../hooks/useTheme';

// Styles
import '../../styles/styles-components/login.css';

// Components
import SignEvalTextField from '../../components/SignEvalTextField';
import SignEvalButton from '../../components/SignEvalButton';

// Images
import logoSignEval from '../../assets/images/logoSignEval.png';

// MUI imports
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

type FormValues = {
    login: string;
    password: string;
};

const Login: React.FC = () => {
    const navigate = useNavigate();
    const theme = useTheme();

    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm<FormValues>();

    const onSubmit: SubmitHandler<FormValues> = async (data) => {
        // Il manque ici la logique de connexion avec les bons appels API
        console.log(data); // sera ensuite supprimé
        navigate('/sessions');
    };

    return (
        <div className="connection" style={{ backgroundColor: theme.palette.background.default }}>
            <img id="logo" src={logoSignEval} alt="logo" className="logo" />
            <form id="form" onSubmit={handleSubmit(onSubmit)}>
                <SignEvalTextField
                    icon={<AccountCircleOutlinedIcon />}
                    iconPosition="end"
                    registerProps={{
                        ...register('login', {
                            required: true,
                            pattern: {
                                value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/,
                                message: i18n.t('Login.error.trainingCenterPattern')
                            }
                        })
                    }}
                    id="trainingCenter"
                    label={i18n.t('Login.placeholder.login')}
                    type="text"
                />
                <SignEvalTextField
                    icon={<LockOutlinedIcon />}
                    iconPosition="end"
                    registerProps={{
                        ...register('password', {
                            required: true,
                            pattern: {
                                value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W)[a-zA-Z\d\W]{8,}$/,
                                message: i18n.t('Login.error.trainingCenterPattern')
                            }
                        })
                    }}
                    id="password"
                    label={i18n.t('Login.placeholder.password')}
                    type="password"
                />
                {(errors.login || errors.password) && <p className="error">{i18n.t('Login.error.login-password')}</p>}
            </form>
            <div className="logActions">
                <SignEvalButton size="large" backgroundColor="blue" onClick={handleSubmit(onSubmit)}>
                    {i18n.t('Login.link.logIn')}
                </SignEvalButton>
                <div id="signUpActions">
                    <p style={{ color: theme.palette.text.primary }}>{i18n.t('Login.withoutAccount')}</p>
                    <Link id="signUpButton" to="/signup-info">
                        <p style={{ color: theme.palette.text.primary }}>{i18n.t('Login.link.signUp')}</p>
                    </Link>
                </div>
                <Link id="passwordForgotten" to="/password-forgotten">
                    <p style={{ color: theme.palette.text.primary }}>{i18n.t('Login.link.passwordForgotten')}</p>
                </Link>
            </div>
        </div>
    );
};

export default Login;
