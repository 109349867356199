import React, { useState } from 'react';
import { useTheme } from '../../hooks/useTheme';
import i18n from '../../i18n/i18n';

// Images
import logoSignEval from '../../assets/images/logoSignEval.png';

// Components
import HeaderBar from '../../components/HeaderBar';
import ContentInfo from '../../components/ContentInfo';
import Avatar from '../../components/Avatar';
import SignEvalButton from '../../components/SignEvalButton';
import SignEvalTextField from '../../components/SignEvalTextField';
import { ChakraPhone } from '../../components/ChakraPhone';

// MUI imports
import PersonAddAlt1OutlinedIcon from '@mui/icons-material/PersonAddAlt1Outlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

import MailOutlinedIcon from '@mui/icons-material/MailOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import Badge from '@mui/material/Badge';

// Chakra-UI imports
import { Collapse } from '@chakra-ui/react';
import { ChakraProvider } from '@chakra-ui/react';

const Center = () => {
    const theme = useTheme();

    const [showInviteTrainer, setShowInviteTrainer] = useState(false);
    const [centerLogo, setCenterLogo] = useState<string | undefined>(undefined);

    const handleShowInviteTrainer = () => {
        setShowInviteTrainer(!showInviteTrainer);
    };

    const handleChangeCenterLogo = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files[0]) {
            const reader = new FileReader();
            reader.onload = (event) => {
                setCenterLogo(event.target?.result as string);
            };
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    const handleBadgeClick = () => {
        const fileInput = document.getElementById('file-input');
        if (fileInput) {
            fileInput.click();
        }
    };

    const [phoneValue, setPhoneValue] = useState<string>('');

    const updateColor = theme.palette.mode === 'light' ? '#5BB3D9' : '#3F4C59';
    const updateBgColor = theme.palette.mode === 'light' ? '#F9F9F9' : '#5BB3D9';
    const updateBoxShadow = theme.palette.mode === 'light' ? '0px 3px 7px 0px rgba(0,0,0,0.15)' : 'none';

    return (
        <div
            id="center"
            style={{
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: theme.palette.background.default,
                color: theme.palette.text.primary,
                width: '100%',
                height: '100vh',
                alignItems: 'center'
            }}
        >
            <HeaderBar />
            <div style={{ marginTop: '0.5em', width: '91vw' }}>
                <ContentInfo flexDirection="column">
                    <Badge
                        badgeContent={
                            <EditOutlinedIcon
                                style={{
                                    width: '1.1em',
                                    height: '1.1em',
                                    color: updateColor,
                                    backgroundColor: updateBgColor,
                                    boxShadow: updateBoxShadow,
                                    borderRadius: '100%',
                                    padding: '2px'
                                }}
                                onClick={handleBadgeClick}
                            />
                        }
                    >
                        <img
                            id="logo"
                            src={centerLogo || logoSignEval}
                            alt="logo"
                            style={{
                                maxWidth: '12em',
                                maxHeight: '7em',
                                width: 'auto',
                                height: 'auto'
                            }}
                        />
                    </Badge>
                    <input id="file-input" type="file" style={{ display: 'none' }} onChange={handleChangeCenterLogo} />
                    <p style={{ width: 'auto', color: theme.palette.text.primary, fontSize: '1em', fontWeight: '600' }}>Quizzbox Solutions</p>
                </ContentInfo>
                <ContentInfo flexDirection="column">
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '85%', padding: '0.35em' }}>
                        <Avatar firstName="Randal" lastName="Kolo Muani" size="medium" />
                        <p style={{ color: theme.palette.secondary.main, fontSize: '1.4em', fontWeight: '600', marginLeft: '1.5em' }}>Randal Kolo Muani</p>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '85%', padding: '0.35em' }}>
                        <Avatar firstName="Jean" lastName="Michel" size="medium" />
                        <p style={{ color: theme.palette.secondary.main, fontSize: '1.4em', fontWeight: '600', marginLeft: '1.5em' }}>Jean Michel</p>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '85%', padding: '0.35em' }}>
                        <Avatar firstName="Jean" lastName="Tanrien" size="medium" />
                        <p style={{ color: theme.palette.secondary.main, fontSize: '1.4em', fontWeight: '600', marginLeft: '1.5em' }}>Jean Tanrien</p>
                    </div>
                </ContentInfo>
                <SignEvalButton variant="contained" backgroundColor="blue" size="large" endIcon={<PersonAddAlt1OutlinedIcon />} style={{ marginBottom: '1.25em' }} onClick={handleShowInviteTrainer}>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <p style={{ color: theme.palette.text.secondary }}>{i18n.t('Center.invite')}</p>
                </SignEvalButton>
                <Collapse
                    in={showInviteTrainer}
                    style={{
                        height: 'auto',
                        boxShadow: theme.palette.mode === 'light' ? '0px 3px 7px 0px rgba(0,0,0,0.15)' : 'none',
                        backgroundColor: theme.palette.mode === 'light' ? '#FFFFFF' : '#3F4C59',
                        borderRadius: '10px',
                        marginBottom: '1.25em'
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            width: '95%',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            backgroundColor: theme.palette.mode === 'light' ? '#FFFFFF' : '#3F4C59',
                            borderRadius: '10px',
                            padding: '1em 0'
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                width: '97.5%',
                                marginBottom: '0.5em',
                                marginLeft: 'auto',
                                marginRight: 'auto',
                                color: theme.palette.secondary.main
                            }}
                        >
                            <p>{i18n.t('Center.invite')}</p>
                            <CloseOutlinedIcon onClick={handleShowInviteTrainer} />
                        </div>
                        <SignEvalTextField
                            className="content"
                            icon={<AccountCircleOutlinedIcon />}
                            iconPosition="end"
                            label={i18n.t('Center.inputInvite.firstName')}
                            type="text"
                            style={{ width: '100%', marginBottom: '1em', marginLeft: 'auto', marginRight: 'auto' }}
                        />
                        <SignEvalTextField
                            className="content"
                            icon={<AccountCircleOutlinedIcon />}
                            iconPosition="end"
                            label={i18n.t('Center.inputInvite.lastName')}
                            type="text"
                            style={{ width: '100%', marginBottom: '1em', marginLeft: 'auto', marginRight: 'auto' }}
                        />
                        <SignEvalTextField
                            className="content"
                            icon={<MailOutlinedIcon />}
                            iconPosition="end"
                            label={i18n.t('Center.inputInvite.email')}
                            type="email"
                            style={{ width: '100%', marginBottom: '1em', marginLeft: 'auto', marginRight: 'auto' }}
                        />
                        <ChakraProvider>
                            <ChakraPhone value={phoneValue} onChange={setPhoneValue} />
                        </ChakraProvider>
                        <SignEvalButton className="content" variant="contained" backgroundColor="blue" size="large" style={{ margin: ' 1em auto 0 auto' }}>
                            {i18n.t('Center.button')}
                        </SignEvalButton>
                    </div>
                </Collapse>
            </div>
        </div>
    );
};

export default Center;
