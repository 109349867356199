// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.92.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.92.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
    width: 100%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
}

#appbar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 75px;
    color: black;
    box-shadow: none;
    padding: 5px;
}

#buttonBack {
    display: flex;
    width: 24.5px;
    height: 24.5px;
}

.logo {
    display: flex;
    width: 110px;
    height: auto;
    margin-top: 10px;
}

#linkAccount {
    text-decoration: none;
}

#linkAccount {
    padding-right: 10px;
    text-decoration: none;
}

#linkAccount {
    padding-right: 10px;
    text-decoration: none;
}`, "",{"version":3,"sources":["webpack://./src/styles/styles-components/headerBar.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,mBAAmB;IACnB,WAAW;IACX,YAAY;IACZ,YAAY;IACZ,gBAAgB;IAChB,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,aAAa;IACb,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,YAAY;IACZ,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,mBAAmB;IACnB,qBAAqB;AACzB;;AAEA;IACI,mBAAmB;IACnB,qBAAqB;AACzB","sourcesContent":[".header {\n    width: 100%;\n    height: auto;\n    margin-left: auto;\n    margin-right: auto;\n}\n\n#appbar {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    align-items: center;\n    width: 100%;\n    height: 75px;\n    color: black;\n    box-shadow: none;\n    padding: 5px;\n}\n\n#buttonBack {\n    display: flex;\n    width: 24.5px;\n    height: 24.5px;\n}\n\n.logo {\n    display: flex;\n    width: 110px;\n    height: auto;\n    margin-top: 10px;\n}\n\n#linkAccount {\n    text-decoration: none;\n}\n\n#linkAccount {\n    padding-right: 10px;\n    text-decoration: none;\n}\n\n#linkAccount {\n    padding-right: 10px;\n    text-decoration: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
